






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.searchList {
  padding: 0 !important;
  .listLabel {
    margin-left: 10px;
  }
  .listInput {
    width: 90%;
  }
}
.framePage-body {
  position: relative;
  padding: 20px;
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    float: left;
    line-height: 38px;
    margin-right: 40px;
  }
  .shell {
    float: left;
  }
  .el-form-item {
    width: 600px;
    margin-bottom: 15px;
    // .tb-edit .el-input {
    //   display: none;
    // }

    // .tb-edit .current-row .el-input {
    //   display: block;
    // }

    // .tb-edit .current-row .el-input + span {
    //   display: none;
    // }
    .szwidth {
      // width: 200px;
      margin-right: 10px;
    }
  }
  .serialNumberType {
    width: 60rem;
  }
  .operations_1 {
    position: absolute;
    right: 0;
    top: 20px;
    .el-button {
      margin-right: 20px;
    }
    .el-select {
      width: 150px;
      float: left;
      margin-right: 20px;
    }
  }
  .operations_2 {
    .el-button {
      padding: 10px 20px;
    }
  }
}
.generateStatementAccount
  .framePage-body
  .operations_1
  /deep/
  .el-select
  .el-input__icon {
  line-height: 33px !important;
}
.generateStatementAccount .operations_2 /deep/ .el-form-item__content {
  text-align: center !important;
}
.generateStatementAccount /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.generateStatementAccount /deep/ .el-textarea__inner {
  height: 200px;
}
.generateStatementAccount /deep/ .el-table th {
  padding: 0 !important;
}
.generateStatementAccount /deep/ .el-divider {
  clear: both;
}
.generateStatementAccount
  /deep/
  .el-table--enable-row-transition
  .el-table__body
  td {
  padding: 0;
}
.generateStatementAccount /deep/ .el-dialog__body {
  overflow: hidden;
  .block {
    width: 100%;
    .el-image {
      width: 48% !important;
      float: left;
      margin-bottom: 8px;
      margin-left: 1.5%;
    }
  }
}
.tb-edit /deep/ .el-input__inner {
  border: none;
}
.el-table /deep/ .cell {
  padding: 0;
}
